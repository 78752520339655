import withRoot from "../utils/withRoot";
import React from "react";

const Component = () => {
  return (
    <p>Hey! You just hit a page that doesn't exist...</p>
  );
};

export default withRoot(Component);
